/* 共通 - common
----------------------------------- */

img {
	width: 100%;
}

.screen-reader-text {
	position: absolute;
	width: 1px;
	height: 1px;
	clip: rect(1px, 1px, 1px, 1px);
	overflow: hidden;
}
