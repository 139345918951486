img {
	width: 100%;
}

.screen-reader-text {
	position: absolute;
	width: 1px;
	height: 1px;
	clip: rect(1px, 1px, 1px, 1px);
	overflow: hidden;
}

.header {
	background: #fff;
	position: fixed;
	z-index: 999;
	top: 0;
	left: 0;
	width: 100%;
}

.header .container {
	display: flex;
	justify-content: space-between;
	max-width: 1140px;
	padding: 0.5em 0;
}

.header-logo {
	margin-bottom: 0;
	width: 20%;
}

.header .list-inline-item:not(:last-of-type) {
	border-right: 1px solid #333;
	padding-right: 0.5em;
}

.header-link {
	color: #333;
	transition: all 0.2s ease-in-out;
}

.header-link:hover,
.header-link:active,
.header-link:focus {
	text-decoration: underline;
	color: #333;
}

.num-list {
	display: flex;
	flex-wrap: wrap;
	padding: 0;
	list-style: none;
}

.num-list-item {
	width: 32%;
	background: #ffab40;
	border-radius: 50%;
	height: 297px;
}

.num-list-item:not(:nth-of-type(3n)) {
	margin-right: 2%;
}

.num-list-item:not(:first-of-type):not(:nth-of-type(2)):not(:nth-of-type(3)) {
	margin-top: 2%;
}

.num-list-inner {
	overflow-y: auto;
	height: calc(100% - 5.25em);
}

.num-list-num {
	font-size: 3.5em;
	font-weight: 600;
}

.num-list-text {
	width: 80%;
	margin: 0 auto;
	font-size: 1.125em;
}

.page-title {
	margin-bottom: 3rem;
	text-align: center;
}

.content:not(:first-child) {
	margin-top: 8em;
}

.document-block {
	margin-top: 5em;
}

.document h3 {
	margin-top: 2rem;
}

.document ol {
	padding-left: 1em;
}

.document li:not(:first-of-type) {
	margin-top: 0.25em;
}

.container {
	max-width: 960px;
}

.speech-area {
	display: flex;
	align-items: flex-start;
	justify-content: center;
}

.speech-bubble {
	padding: 2em;
	position: relative;
	background: url(../img/common/speech-buble.png) no-repeat;
	background-size: 100% 100%;
}

.speech-bubble-gray {
	background-image: url(../img/common/speech-buble-gray.png);
	padding-left: 2.75em;
}

.footer {
	background: #999;
	margin-top: 8em;
}

.footer .container {
	padding-top: 1em;
	padding-bottom: 1em;
}

.footer-link {
	color: #333;
	transition: all 0.2s ease-in-out;
}

.footer-link:hover,
.footer-link:active,
.footer-link:focus {
	text-decoration: underline;
	color: #333;
}

.button {
	display: inline-block;
}

.button-main {
	background: #0097a7;
	color: #fff;
	font-weight: 900;
	border-radius: 5px;
	padding: 1% 2%;
	max-width: 500px;
	width: 100%;
	font-size: 2em;
	transition: all 0.2s ease-in-out;
}

.button-main:hover,
.button-main:active,
.button-main:focus {
	background: #ffab40;
	color: #fff;
	text-decoration: none;
}

.button-sub {
	background: #76a5af;
	color: #fff;
	padding: 1% 2%;
	border-radius: 5px;
	font-weight: 900;
	transition: all 0.2s ease-in-out;
}

.button-sub:hover,
.button-sub:active,
.button-sub:focus {
	color: #fff;
	text-decoration: none;
	background: #ffab40;
}

.title-main {
	text-align: center;
	font-size: 2em;
	border-bottom: 2px solid #ffab40;
	font-weight: 900;
	padding-bottom: 0.5em;
	position: relative;
	margin-bottom: 1em;
}

.title-main img {
	width: 10%;
	position: absolute;
	top: 30%;
	right: 0;
}

